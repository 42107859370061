<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="상품 통계(신규)">
        <tobe></tobe>
      </b-tab>
      <b-tab title="상품 통계(이전)">
        <asis></asis>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import tobe from '@/views/data/GoodsStatTobe.vue'
import asis from '@/views/data/GoodsStatAsis.vue'

export default {
  name: 'GoodsStat',
  title: '상품 통계',
  components: {tobe, asis},
  data() {
    return {
      tabIndex: 0,
    }
  }
}
</script>
